/** When your routing table is too long, you can split it into small modules**/
const router = {
    path: 'myapps',
    name: 'myapps',
    component: () => import('@/components/RouterView'),
    redirect: '/sp/myapps/list',
    meta: {
        title: 'page.route.myapps',
        icon: 'sidebar-myapps',
        auth: true
    },
    children: [
        // 我的应用列表
        {
            path: 'list',
            name: 'myapps-list',
            component: () => import('@/views/myapps/list'),
            meta: {
                title: 'page.route.myapps',
                breadcrumb: false,
                auth: true
            }
        },
        // 接入信息开发环境
        {
            path: ':id/access',
            name: 'myapps-access',
            component: () => import('@/views/myapps/access'),
            meta: {
                title: 'page.route.access',
                activeMenu: '/sp/myapps/list',
                auth: true
            },
            props: true,
            hidden: true
        }
    ]
};

export default router;
