/** When your routing table is too long, you can split it into small modules**/
const router = {
    path: 'message',
    name: 'message',
    component: () => import('@/views/message'),
    meta: {
        title: 'page.route.message',
        icon: 'sidebar-message'
    }
};

export default router;
