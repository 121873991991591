<template>
    <div v-if="!item.hidden" class="menu-wrapper">
        <template v-if="!hasSub(item)">
            <component v-if="showRoute.meta" :is="componentType(showRoute.path)" :to="resolvePath(showRoute.path)">
                <el-menu-item :index="resolvePath(showRoute.path)" :class="{'submenu-title-noDropdown': !isNest}">
                    <item :icon="showRoute.meta.icon || (item.meta && item.meta.icon)" :title="$t(showRoute.meta.title)" />
                </el-menu-item>
            </component>
        </template>

        <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
            <template slot="title">
                <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="$t(item.meta.title)" />
            </template>
            <sidebar-item
                v-for="child in item.children"
                :key="child.path"
                :is-nest="true"
                :item="child"
                :base-path="resolvePath(item.path)"
                class="nest-menu"
            />
        </el-submenu>
    </div>
</template>

<script>
import path from 'path';
import {isExternal} from '@/utils/validate';
import Item from './Item';
import AppLink from './Link';

export default {
    name: 'sidebar-item',
    components: {Item, AppLink},
    props: {
        // route object
        item: {
            type: Object,
            required: true
        },
        isNest: {
            type: Boolean,
            default: false
        },
        basePath: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showRoute: null
        };
    },
    methods: {
        hasSub(route) {
            const {children = []} = route;
            // none child
            if (children.length === 0) {
                this.showRoute = route;
                return false;
            }

            const subRoutes = children.filter(item => {
                if (item.hidden) {
                    return false;
                } else {
                    return true;
                }
            });

            // When there is only one child router, the child router is displayed by default
            if (subRoutes.length === 1) {
                const {path: subRoutePath, ...subRoute} = subRoutes[0];
                const {path: routePath} = route;
                this.showRoute = {...subRoute, path: isExternal(subRoutePath) ? subRoutePath : path.resolve(this.basePath, routePath, subRoutePath)};
                return this.showRoute.alwaysShow ? true : false;
            }

            return true;
        },
        resolvePath(routePath) {
            // url标准地址
            if (isExternal(routePath)) {
                return routePath;
            }

            return path.resolve(this.basePath, routePath);
        },
        componentType(path) {
            return isExternal(path) ? 'AppLink' : 'RouterLink';
        }
    }
};
</script>
