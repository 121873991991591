import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import myapps from './modules/myapps';
import mytrack from './modules/mytrack';
import serviceCenter from './modules/serviceCenter';
import personalCenter from './modules/personalCenter';
import message from './modules/message';
import download from './modules/download';
import documentation from './modules/documentation';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set,
    loginWhite: false            if set true, login will not be validated
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/sp/login',
        name: 'login',
        component: () => import('@/views/login/index'),
        hidden: true,
        meta: {loginWhite: true}
    },
    // 找回密码
    {
        path: '/sp/retrieve-password',
        name: 'retrieve-password',
        component: () => import('@/views/retrievePassword'),
        hidden: true,
        meta: {loginWhite: true}
    },
    // 密码重置
    {
        path: '/sp/reset-password/:token',
        name: 'reset-password',
        component: () => import('@/views/retrievePassword/resetPassword'),
        hidden: true,
        props: true,
        meta: {loginWhite: true}
    },
    // 密码重置成功
    {
        path: '/sp/reset-password-success',
        name: 'reset-password-success',
        component: () => import('@/views/retrievePassword/resetPasswordSuccess'),
        hidden: true,
        meta: {loginWhite: true}
    },
    // 注册
    {
        path: '/sp/register',
        name: 'register',
        component: () => import('@/views/register'),
        hidden: true,
        meta: {loginWhite: true}
    },
    // 注册成功
    {
        path: '/sp/register-success',
        name: 'register-success',
        component: () => import('@/views/register/registerSuccess'),
        hidden: true,
        meta: {loginWhite: true}
    },
    // 邀请入驻完成
    {
        path: '/sp/invite/:token',
        name: 'invite',
        component: () => import('@/views/register/invite'),
        hidden: true,
        props: true,
        meta: {loginWhite: true}
    },
    // 邀请入驻完成
    {
        path: '/sp/invite-success',
        name: 'invite-success',
        component: () => import('@/views/register/inviteSuccess'),
        hidden: true,
        meta: {loginWhite: true}
    },
    {
        path: '/sp/404',
        component: () => import('@/views/404'),
        hidden: true,
        meta: {loginWhite: true, title: 'page.route.page404'}
    }
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = {
    path: '/sp',
    name: 'side',
    component: Layout,
    redirect: '/sp/dashboard',
    children: [
        {
            path: 'dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashboard/list'),
            meta: {
                title: 'page.route.dashboard',
                icon: 'sidebar-dashboard',
                permissionWhite: true
            }
        },
        myapps,
        mytrack,
        serviceCenter,
        personalCenter,
        message,
        download,
        documentation
    ]
};

const createRouter = () =>
    new Router({
        mode: 'history', // require service support
        scrollBehavior: () => ({y: 0}),
        routes: [...constantRoutes, {...asyncRoutes}, {path: '*', redirect: '/sp/404', hidden: true, meta: {breadcrumb: false}}]
    });

const router = createRouter();

export default router;
