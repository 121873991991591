/** When your routing table is too long, you can split it into small modules**/
const router = {
    path: 'download',
    name: 'download',
    component: () => import('@/views/download'),
    meta: {
        title: 'page.route.download',
        icon: 'sidebar-download',
        auth: true
    }
};

export default router;
