export default {
    accessSteps: '接入步骤',
    createApp: '创建应用',
    whitelist: '白名单',
    appName: '应用名称',
    appStatus: '应用状态',
    createTime: '创建时间',
    accessInfo: '接入信息',
    status_0: '签约中',
    status_1: '签约完成',
    status_2: '停止签约',
    status_undefined: '',
    appLogo: '应用Logo',
    appLogoTips_1: '格式：支持格式为JPG，PNG',
    appLogoTips_2: '尺寸：120*120px',
    appLogoTips_3: '大小：不能超过1M',
    appType: '应用类型',
    appDescription: '应用描述',
    service: '服务',
    program: '方案',
    logoError: '应用Logo不符合要求',
    intentionBizOptions_1: '提供音乐播放服务',
    intentionBizOptions_2: '提供含短音频及合成的服务',
    intentionBizOptions_3: '音乐应用到硬件设备上使用',
    intentionBizOptions_undefined: '',
    intentionPlanOptions_1: 'API',
    intentionPlanOptions_2: 'SDK',
    intentionPlanOptions_undefined: '',
    logoRequired: '请上传应用Logo',
    nameRequired: '请填写应用名称',
    descRequired: '请填写用描述',
    appCategoryIdRequired: '请选择应用类型',
    intentionBizRequired: '请选择服务',
    viewApp: '查看应用',
    whitelistTips:
        '请填写公网IP地址或者IP地址段，其中支持最多20条的公网IP地址和1条C段IP地址，IP地址写法为 X.X.X.X/32，例如：22.45.33.29/32；C段的地址写法为X.X.X.0/24，例如：22.45.33.0/24,。提交后需要在1小时后生效，请注意。',
    dev: '开发环境',
    pro: '生产环境',
    apiAccess: 'API接入',
    androidSDKAccess: 'Android SDK接入',
    iosSDKAccess: 'iOS SDK接入',
    nonProTips: '需要签约完成后才能使用生产环境',
    remarks: '备注',
    remarksTips_dev: '开发环境调试地址：https://dev-openapi.dmhmusic.com/',
    remarksTips_pro: '生产环境调试地址：https://openapi.dmhmusic.com/',
    remarksTips: '接入相关，请查看',
    accessDoc: 'API接入文档',
    reset: '重置',
    resetConfirm: '是否要重置？重置后原Code不再生效'
};
