/** When your routing table is too long, you can split it into small modules**/
const router = {
    path: 'personalCenter',
    name: 'personalCenter',
    component: () => import('@/components/RouterView'),
    redirect: '/sp/personalCenter/basicInformation',
    meta: {
        title: 'page.route.personalCenter',
        icon: 'sidebar-personalCenter'
    },
    children: [
        // 基本信息
        {
            path: 'basicInformation',
            name: 'personalCenter-basicInformation',
            component: () => import('@/views/personalCenter/basicInformation'),
            meta: {
                title: 'page.route.basicInformation'
            }
        },
        // 认证信息
        {
            path: 'certificationInformation',
            name: 'personalCenter-certificationInformation',
            component: () => import('@/views/personalCenter/certificationInformation'),
            meta: {
                title: 'page.route.certificationInformation'
            }
        }
    ]
};

export default router;
