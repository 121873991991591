/** When your routing table is too long, you can split it into small modules**/
const router = {
    path: 'serviceCenter',
    name: 'serviceCenter',
    component: () => import('@/components/RouterView'),
    redirect: '/sp/serviceCenter/errorLog',
    meta: {
        title: 'page.route.serviceCenter',
        icon: 'sidebar-serviceCenter',
        auth: true
    },
    children: [
        // 我的应用列表
        {
            path: 'errorLog',
            name: 'serviceCenter-errorLog',
            component: () => import('@/views/serviceCenter/errorLog'),
            meta: {
                title: 'page.route.errorLog',
                auth: true
            }
        },
        {
            path: 'callQuery',
            name: 'serviceCenter-callQuery',
            component: () => import('@/views/serviceCenter/callQuery'),
            meta: {
                title: 'page.route.callQuery',
                auth: true
            }
        }
    ]
};

export default router;
