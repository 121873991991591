import Vue from 'vue';
import AudioPlay from './AudioPlay';

AudioPlay.installInfo = (function() {
    let component;
    return function(options) {
        if (!component) {
            const AudioPlayComponent = Vue.extend(AudioPlay);
            component = new AudioPlayComponent({});
        }

        component.playNew(options);
    };
})();

export default {
    install: Vue => {
        Vue.prototype.$audioPlay = AudioPlay.installInfo;
    }
};
