export default {
    title: '找回密码',
    placeholderCellphone: '手机号',
    placeholderSMSVerificationCode: '短信验证码',
    verificationCode: '获取验证码',
    telCountryRequired: '请选择国家/地区区号',
    telephoneRequired: '请输入正确手机号',
    countdownText: '{m}秒后重新获取',
    smsCodeRequired: '请输入验证码',
    resetPassword: '重设密码',
    placeholderPassword: '请输入密码（需包含字母和数字）',
    placeholderConfirmPassword: '确认密码',
    passwordRequired: '请输入密码',
    passwordError: '密码必须包含字母和数字，长度6-30',
    confirmPasswordRequired: '请确认密码',
    inconsistentPasswords: '新密码两次输入不一致',
    resetPasswordSuccess: '重设密码成功！',
    login: '立即登录'
};
