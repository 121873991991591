import Vue from 'vue';
import {CancelToken} from 'axios';
import {extend} from './extend';
import AudioPlay from '@/components/AudioPlay';

// Music play
Vue.use(AudioPlay);
Vue.prototype.$extend = extend;

//Download
Vue.prototype.$download = function(path, fileName = '') {
    let form = document.querySelector('#downloadForm');
    if (!form) {
        const fileNameInput = document.createElement('input');
        const fileUrlInput = document.createElement('input');

        fileNameInput.name = 'fileName';
        fileUrlInput.name = 'fileUrl';

        form = document.createElement('form');
        form.id = 'downloadForm';
        form.style.display = 'none';
        form.action = `${process.env.VUE_APP_BASE_API}/file/download_by_file_url.json`;
        form.method = 'post';

        form.appendChild(fileNameInput);
        form.appendChild(fileUrlInput);

        document.body.appendChild(form);
    }

    if (fileName) {
        const fileName_format = fileName.split('.').pop();
        const path_format = path.split('.').pop();

        if (fileName_format.toLowerCase() !== path_format) {
            fileName = `${fileName}.${path_format}`;
        }
    }

    form.fileName.value = fileName;
    form.fileUrl.value = path;

    form.submit();
};

//Download A
Vue.prototype.$downloadA = function(path, fileName) {
    let a = document.querySelector('#downloadA');
    if (!a) {
        a = document.createElement('a');
        a.id = 'downloadA';
        a.style.display = 'none';
        document.body.appendChild(a);
    }

    a.href = path;
    a.download = fileName ? fileName : '';
    a.click();
};

// Copy
Vue.prototype.$copy = function(content) {
    var input = document.createElement('input');
    input.value = content;
    input.style.cssText = 'position: fixed;left: -100%; z-index: -9999;';

    document.body.appendChild(input);

    input.select();

    document.execCommand('copy');
    document.body.removeChild(input);
};

// Form validation
Vue.prototype.$validate = function(form, fn) {
    const vForm = this.$refs[form];
    vForm.validate((valid, object) => {
        if (!valid) {
            this.$nextTick().then(() => {
                const errFiled = vForm.$el.querySelector('.is-error input,.is-error select,.is-error textarea,.is-error .el-upload');
                errFiled && errFiled.focus();
            });
        }
        fn(valid, object);
    });
};

// axios CancelToken
Vue.prototype.$CancelToken = CancelToken;

// Server error message
Vue.prototype.$messageError = function(res) {
    // 屏蔽未登录信息提示 使用MessageBox在request中统一处理
    if (res.data.code === 'notlogin') {
        return false;
    }

    this.$message.error(res.data.msg);

    return false;
    // Error log capture
};

// Access the parameter
const cacheParamsLimit = 15;
const cacheParamsKey = '_c_t_';

const cacheParams = function(path, key, data) {
    const cacheParamsData = JSON.parse(localStorage.getItem(cacheParamsKey) || '[]').filter(item => {
        return path !== item.path;
    });

    if (cacheParamsData.length === cacheParamsLimit) {
        cacheParamsData.pop();
    }

    cacheParamsData.unshift({data: {...data}, key, path});

    localStorage.setItem(cacheParamsKey, JSON.stringify(cacheParamsData));
};

// Get query parameters
Vue.prototype.$propsQuery = function() {
    const {path, query} = this.$route;
    const t = query.t;

    if (!t) {
        return {};
    }

    const props = JSON.parse(localStorage.getItem(cacheParamsKey) || '[]').find(item => {
        return path === item.path && t === item.key;
    });

    return props ? props.data : {};
};

// Page redirect
Vue.prototype.$redirect = function(filterData) {
    const {name, path, params} = this.$route;

    const t = Date.now().toString();

    // Cache query parameters to prevent modification on URL addresses
    cacheParams(path, t, filterData);

    this.isRedirect = true;

    this.$router.replace({name: name, params: {...params}, query: {t}});
};

Vue.prototype.$isRedirect = function() {
    const redirect = this.isRedirect;
    this.isRedirect = false;
    return redirect;
};
