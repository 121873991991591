export default {
    title: '注册后，专员将联系您',
    placeholderUsername: '用户名称',
    placeholderCellphone: '手机号',
    placeholderSMSVerificationCode: '短信验证码',
    placeholderPassword: '请输入密码（需包含字母和数字）',
    verificationCode: '获取验证码',
    service: '《服务条款》',
    agree: '同意并遵守',
    submit: '立即注册',
    registerSuccess: '注册成功!',
    registerSuccessTips: '尊敬的用户，您已成功注册DMH，请点击登录！',
    login: '立即登录',
    verificationCodePlugError: '验证码插件初始化失败',
    telCountryRequired: '请选择国家/地区区号',
    telephoneRequired: '请输入正确手机号',
    countdownText: '{m}秒后重新获取',
    nameRequired: '请输入用户名称',
    passwordRequired: '请输入密码',
    passwordError: '密码必须包含字母和数字，长度6-30',
    smsCodeRequired: '请输入验证码',
    notAgreeService: '注册需要同意服务条款',
    inviteSuccess: '入驻邀请已完成！',
    inviteSuccessTips: '您好！本链接的入驻邀请已经完成注册了，请登录。',
    invite: '邀请您注册',
    inviteTips: '欢迎贵公司“{m}”成为太合发行的合作伙伴，为了更好为贵公司提供服务，请设置个人信息，以便实时追逐贵公司的业务情况。',
    inviteUsedConfirm: '您好！本链接的入驻邀请已经完成注册，请直接登录'
};
