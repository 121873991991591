import Vue from 'vue';
import moment from 'moment';

Vue.filter('resolveImgPath', path => {
    if (!path) {
        return '';
    }

    if (/^(https?:|mailto:|tel:)/.test(path)) {
        return path;
    }

    return `${process.env.VUE_APP_CDN_IMG}${path}`;
});

Vue.filter('resolveVideoPath', path => {
    return `${process.env.VUE_APP_CDN_VIDEO}${path}`;
});

Vue.filter('dateFormatter', (time, fmStr) => {
    if (!time) {
        return '';
    }
    return moment(time).format(fmStr);
});

Vue.filter('resolveFilePath', path => {
    return path ? `${process.env.VUE_APP_CDN_FILE}${path}` : '';
});

let uuid = new Date().getTime();

/** 解决没有唯一key属性for循环问题 */
Vue.filter('key', ob => {
    if (typeof ob === 'number') {
        return ob;
    }
    return ob.$uuid ? ob.$uuid : (ob.$uuid = uuid++);
});
