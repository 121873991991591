/** When your routing table is too long, you can split it into small modules**/
const router = {
    path: 'mytrack',
    name: 'mytrack',
    component: () => import('@/components/RouterView'),
    redirect: '/sp/mytrack/statistics',
    meta: {
        title: 'page.route.myTrack',
        icon: 'sidebar-mytrack',
        auth: true
    },
    children: [
        // 我的应用列表
        {
            path: 'statistics',
            name: 'myapps-statistics',
            component: () => import('@/views/mytrack/statistics'),
            meta: {
                title: 'page.route.trackStatistics',
                auth: true
            }
        },
        {
            path: 'query',
            name: 'myapps-query',
            component: () => import('@/views/mytrack/query'),
            meta: {
                title: 'page.route.trackQuery',
                auth: true
            }
        }
    ]
};

export default router;
