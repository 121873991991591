const route = {
    dashboard: '概览',
    myapps: '我的应用',
    myTrack: '我的音乐',
    trackStatistics: '音乐统计',
    trackQuery: '音乐查询',
    serviceCenter: '服务中心',
    errorLog: '错误日志',
    callQuery: '调用查询',
    statistics: '数据统计',
    personalCenter: '个人中心',
    basicInformation: '基本信息',
    certificationInformation: '认证信息',
    access: '接入信息',
    message: '我的消息',
    download: 'SDK下载',
    documentation: '帮助文档'
};

const navbar = {
    home: '首页',
    dynamic: '动态',
    aboutUs: '关于我们',
    documentation: '文档帮助',
    download: '下载',
    logOut: '登出',
    msg: '消息({m})'
};

export default {
    copyright: 'Copyright 2020 © .TAIHE MUSIC GROUP. 北京太乐文化科技有限公司版权所有 [京ICP备17054849号] | 京公安网备11010502036992号',
    authConfirm_0: '您好，需要先认证身份后才能使用相关的功能。',
    authConfirm_1: '您好，您提交的认证信息正在审核中。',
    authConfirm_3: '您好，您提交的认证信息审核不通过。',
    authBtn_0: '去认证',
    authBtn_1: '确定',
    authBtn_3: '重新认证',
    placeholderInput: '请填写',
    placeholderInputContent: '请输入内容',
    placeholderSelect: '请选择',
    search: '查询',
    reset: '重置',
    operation: '操作',
    edit: '编辑',
    del: '删除',
    dateStart: '开始日期',
    dateEnd: '结束日期',
    dateTo: '至',
    subSuccess: '提交成功',
    saveSuccess: '保存成功',
    cancel: '取消',
    submit: '提交',
    save: '保存',
    prompt: '提示',
    confirm: '确定',
    setting: '设置',
    deleteConfirm: '确定删除?',
    deleteSuccess: '删除成功',
    setSuccess: '设置成功',
    view: '查看',
    newOne: '新增一个',
    loading: '加载中',
    empty: '暂无数据',
    noData: '无数据',
    to: '至',
    couldNotFind: '没有找到',
    startTime: '开始时间',
    endTime: '结束时间',
    add: '添加',
    filter: '筛选',
    exportData: '导出数据',
    downloadData: '下载数据',
    seeDetail: '查看详情',
    operationSuccess: '操作成功',
    select: '选择',
    noMatch: '未找到相关内容',
    check: '查看',
    detail: '详情',
    confirm1: '确认',
    audition: '试听',
    download: '下载',
    preview: '预览',
    default: '默认',
    reLogin: '重新登录',
    clickUpload: '点击上传',
    reLoginConfirm: '您已经登出，您可以取消停留在此页面，或再次登录',
    versionChangeConfirm: '服务器版本已经更新，页面刷新后可以恢复正常使用',
    route,
    navbar
};
