import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import zhLocale from './zh-CN';

Vue.use(VueI18n);

const messages = {
    // 简体中文
    'zh-hans': {
        ...zhLocale,
        ...elementZhLocale
    }
};

export default new VueI18n({
    // set locale
    // options: en | zh | es
    locale: store.getters.language,
    // set locale messages
    messages
});
