/** When your routing table is too long, you can split it into small modules**/
const router = {
    path: 'documentation',
    name: 'documentation',
    component: () => import('@/components/RouterView'),
    meta: {
        title: 'page.route.documentation',
        icon: 'sidebar-documentation',
        auth: true
    }
};

export default router;
