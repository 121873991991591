<template>
    <div v-if="levelList && levelList.length > 0" class="breadcrumb-container">
        <svg-icon v-if="icon" :icon-class="icon" />
        <el-breadcrumb class="app-breadcrumb" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
                <span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{ $t(item.meta.title) }}</span>
                <a v-else @click.prevent="handleLink(item)">{{ $t(item.meta.title) }}</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <slot name="headerBar" />
    </div>
</template>

<script>
import pathToRegexp from 'path-to-regexp';

export default {
    data() {
        return {
            levelList: null,
            icon: null
        };
    },
    watch: {
        $route(route) {
            // if you go to the redirect page, do not update the breadcrumbs
            if (route.path.startsWith('/redirect/')) {
                return;
            }
            this.getBreadcrumb();
        }
    },
    created() {
        this.getBreadcrumb();
    },
    methods: {
        getBreadcrumb() {
            // only show routes with meta.title
            const matched = this.$route.matched.filter(item => item.meta && item.meta.title);

            if (!matched || matched.length === 0) {
                this.levelList = [];
                return;
            }

            const first = matched[0];

            if (first.meta && first.meta.icon) {
                this.icon = first.meta.icon;
            }
            this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
        },
        isDashboard(route) {
            const name = route && route.name;
            if (!name) {
                return false;
            }
            return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
        },
        handleLink(item) {
            const {redirect, path} = item;
            const {params, path: currentPath} = this.$route;

            const toPath = pathToRegexp.compile(redirect ? redirect : path)(params);

            if (currentPath === toPath) {
                return;
            }

            this.$router.push(toPath);
        }
    }
};
</script>

<style lang="scss" scope>
.breadcrumb-container {
    padding: 20px 0;
    background-color: #fff;

    .svg-icon {
        display: inline-block;
        margin: 0 10px 0 20px;
        font-size: 20px;
        color: #303133;
    }

    .app-breadcrumb {
        display: inline-block;
        font-size: 20px;
        vertical-align: top;

        a {
            font-weight: 400 !important;
        }
    }
}
</style>
