const statistics = {
    trackNum: '歌曲数',
    albumNum: '专辑数',
    artistNum: '艺人数',
    trackListNum: '歌单数',
    radioNum: '电台数',
    billboardNum: '榜单数',
    trackTop100: '歌曲TOP100',
    albumTop100: '专辑TOP100',
    artistTop100: '艺人TOP100',
    allTrackList: '所有歌单',
    allRadio: '所有电台',
    allBillboard: '所有榜单',
    sort: '序号',
    cover: '封面',
    albumName: '专辑名称',
    artistName: '艺人名称',
    trackName: '歌曲名称',
    trackListName: '歌单名称',
    radioName: '电台名称',
    billboardName: '榜单名称'
};

const query = {
    queryKeyOptions_1: '查询歌曲',
    queryKeyOptions_2: '查询艺人',
    queryKeyOptions_3: '查询专辑',
    queryKeyOptions_41: '查询歌单',
    queryKeyOptions_42: '查询电台',
    queryKeyOptions_43: '查询榜单',
    sort: '序号',
    cover: '封面',
    albumName: '专辑名称',
    artistName: '艺人名称',
    trackName: '歌曲名称',
    trackListName: '歌单名称',
    radioName: '电台名称',
    billboardName: '榜单名称'
};

export default {statistics, query};
