<template>
    <div class="sidebar-container">
        <el-menu :default-active="active" :collapse-transition="false" :collapse="isCollapse" :unique-opened="true">
            <sidebar-item v-for="route in permission_routes_sidebar" :key="route.path" :item="route" :base-path="basePath" />
        </el-menu>
    </div>
</template>
<script>
import SidebarItem from './SidebarItem';
import {asyncRoutes} from '@/router';
export default {
    components: {SidebarItem},
    data() {
        return {};
    },
    computed: {
        basePath() {
            return asyncRoutes.path;
        },
        // Sidebar 拥有权限
        permission_routes_sidebar() {
            return asyncRoutes.children.filter(route => !route.hidden);
        },
        // Sidebar 折叠状态
        isCollapse() {
            return !this.$store.getters.sidebar.opened;
        },
        // Sidebar选中状态
        active() {
            const route = this.$route;
            const {meta, path} = route;
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu;
            }

            return path;
        }
    }
};
</script>
