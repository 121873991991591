export default {
    companyNameDefault: '尊敬的用户',
    statusAuthTips_0: '您好，请完成企业认证后才能使用各服务。',
    statusAuthTips_1: '正在审核中。',
    statusAuthTips_2: '欢迎来到DMH，请开始了解您的音乐服务吧。',
    statusAuthTips_3: '认证失败。',
    toAuth: '去认证',
    yesterday: '昨天',
    accountBalance: '账户余额(人民币)',
    totalConsumption: '消费总数(人民币)',
    views: '播放次数',
    accessGuide: '接入指南',
    demoAndDownload: 'Demo & 下载',
    viewDocument: '查看文档',
    understandingDDEX: '了解DDEX',
    ddexWebsite: 'DDEX官网',
    sdkTips: '支持iOS、Android系统的软件接入。接入方便，功能强大。DMH强大的后台帮助您对曲库进行深度的管理与运营。',
    apiTips: '可以避开音乐版权、音乐存储等复杂的管理工作。又可以支持您对音乐的深度定制化需求和来自DMH的运营支持。',
    ddexTips: '若您已有或准备搭建自有的曲库管理，仅希望获取音乐时。建议您使用DDEX国际标准音乐传输协议。'
};
