const getters = {
    language: state => state.app.language,
    sidebar: state => state.app.sidebar,
    config: state => state.app.config,
    privileges: state => state.user.privileges,
    permission: state => state.user.permission,
    permission_routes: state => state.user.permissionRoutes,
    token: state => state.user.token,
    user: state => state.user.info,
    msg: state => state.user.msg
};

export default getters;
