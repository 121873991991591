<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import {getToken} from '@/utils/auth';
import {messagesTask} from '@/api/user';
export default {
    name: 'app',
    created() {
        this.fetch();
    },
    methods: {
        async fetch() {
            if (getToken()) {
                const res = await messagesTask().catch(() => {
                    return {data: {state: false}};
                });

                if (res.data.state) {
                    this.$store.commit('user/SET_MSG', res.data.result);
                }
            }

            setTimeout(() => {
                this.fetch();
            }, 1000 * 60);
        }
    }
};
</script>

<style lang="scss" scoped>
#app {
    height: 100%;
    min-width: 1440px;
}
</style>
