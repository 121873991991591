const certificationInformation = {
    companyName: '公司名称',
    companyAddress: '公司地址',
    companyLogo: '公司Logo',
    businessLicense: '营业执照',
    viewExample: '查看示例',
    companyLogoTips_1: '格式：支持格式为JPG，PNG',
    companyLogoTips_2: '尺寸：120*120px',
    companyLogoTips_3: '大小：不能超过1M',
    businessLicenseTips_1: '格式：支持格式为JPG，PNG',
    businessLicenseTips_2: '大小：不能超过5M',
    creditCode: '统一社会信用代码',
    logoError: '公司Logo不符合要求',
    photoError: '营业执照不符合要求',
    nameRequired: '请填写公司名称',
    addressRequired: '请填写公司地址',
    logoRequired: '请上传公司Logo',
    idPhotoRequired: '请上传营业执照',
    idNumRequired: '请填写统一社会信用代码',
    reviewstatus: '审核状态',
    reviewPass: '审核通过',
    reviewWait: '待审核',
    viewDemo: '查看示例',
    viewDemoTile: '营业执照示例',
    viewDemoTips_1: '1、营业执照格式：jpg或png',
    viewDemoTips_2: '2、大小小于800K',
    viewDemoTips_3: '3、营业执照未过期',
    viewDemoTips_4: '4、需提交清晰的营业执照复印件或照片，并加盖公章、含最新年检'
};

const basicInformation = {
    username: '用户名',
    cellphone: '手机',
    password: '密码',
    edit: '修改',
    registrationTime: '注册时间',
    lastLoginTime: '上次登录时间',
    contactPerson: '联系人',
    mailbox: '邮箱',
    mailboxTips: '使用及结算相关数据，我们会通过邮箱发送给您',
    mailError: '邮箱格式不正确',
    updatePassword: '修改密码',
    oldPassword: '旧密码',
    newPassword: '新密码',
    newPasswordTips: '至少包括1位数字和1位字母',
    confirmPassword: '确认密码',
    confirmPasswordTips: '两次密码必须输入一致',
    oldPasswordRequired: '请填写旧密码',
    passwordRequired: '请填写新密码',
    confirmPasswordRequired: '请确认密码',
    passwordError: '密码必须包含字母和数字，长度6-30',
    inconsistentPasswords: '新密码两次输入不一致',
    changeCellPhoneNumber: '更换手机号码',
    placeholderCellphone: '请填写手机号',
    newCellphone: '新手机号',
    placeholderSMSVerificationCode: '请填写手机验证码',
    verificationCode: '获取验证码',
    verificationCodePlugError: '验证码插件初始化失败',
    telCountryRequired: '请选择国家/地区区号',
    telephoneRequired: '请输入正确手机号',
    countdownText: '{m}秒后重新获取',
    smsCodeRequired: '请输入验证码',
    cellphoneVerificationCode: '手机验证码',
    newCellphoneTips: '新手机号将会替换旧手机号，相关短信将会在变更成功后发送至新手机'
};

export default {
    certificationInformation,
    basicInformation
};
