<template>
    <a :href="to" @click.prevent="handEve" :target="target">
        <slot />
    </a>
</template>

<script>
export default {
    props: {
        to: {
            type: String,
            required: true
        }
    },
    computed: {
        target() {
            return this.to.endsWith('.html') ? '' : '_blank';
        }
    },
    methods: {
        handEve() {
            location.href = this.to;
        }
    }
};
</script>
