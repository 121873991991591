import axios from 'axios';
import store from '@/store';
import {removeToken} from '@/utils/auth';
import i18n from '@/locales';
import {MessageBox} from 'element-ui';

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // api base_url
    timeout: 10 * 60 * 1000 // request timeout
});

// request interceptor
service.interceptors.request.use(
    config => {
        // token set
        if (store.getters.token) {
            config.headers['uvsign'] = store.getters.token;
        }

        // locales set
        if (store.getters.language) {
            config.headers['language'] = store.getters.language;
        }

        // api formatt
        if (!config.url.endsWith('.json')) {
            config.url += '.json';
        }

        return config;
    },
    error => {
        // Do something with request error
        Promise.reject(error);
    }
);

let isNotLoginMessageBox = false;

// response interceptor
service.interceptors.response.use(
    async res => {
        // Stream file
        if (res.request.responseType === 'blob' && res.data instanceof Blob) {
            return res;
        }

        // true--登录验证白名单
        const {isLoginWhite} = res.config.headers;

        // Not login
        if (!isLoginWhite && res.data.code === 'notlogin' && !isNotLoginMessageBox) {
            // 避免连续弹出多次弹出确认框
            isNotLoginMessageBox = true;

            const flag = await MessageBox.confirm(i18n.t('page.reLoginConfirm'), i18n.t('page.prompt'), {
                confirmButtonText: i18n.t('page.reLogin'),
                cancelButtonText: i18n.t('page.cancel')
            }).catch(() => {
                return 'cancel';
            });

            // 重新登录
            if (flag !== 'cancel') {
                removeToken();
                location.reload();
            }

            // 留在当前页面
            isNotLoginMessageBox = false;
        }

        return res;
    },
    error => {
        return Promise.reject(error);
    }
);

export default service;
