<template>
    <div ref="audioPlay" class="audio-play-container" @mouseenter="show" @mouseleave="hide">
        <el-image :src="track.cover | resolveImgPath" :class="['audio-play-cover', status === 'play' ? 'play' : '']" fit="cover">
            <div slot="error" class="image-slot error">
                <svg-icon slot="icon" icon-class="icon-logoDefault"></svg-icon>
            </div>
        </el-image>
        <div v-if="status === 'pause'" class="audio-play-control" @click="play">
            <svg-icon icon-class="icon-pause"></svg-icon>
        </div>
        <div v-if="status === 'play'" class="audio-play-control" @click="pause">
            <svg-icon icon-class="icon-play"></svg-icon>
        </div>
        <div class="audio-play-content">
            <h3>{{ track.name }}</h3>
            <p>{{ track.artist }}</p>
        </div>
        <audio v-show="false" ref="audio" controls="true" autoplay="true" :src="track.src" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            track: {
                src: null,
                name: null,
                cover: null,
                artist: null
            },
            // 播放状态 pause | paly
            status: 'pause',
            isMounted: false
        };
    },
    mounted() {
        const audio = this.$refs.audio;
        audio.addEventListener('ended', () => {
            this.status = 'pause';
        });

        audio.addEventListener('play', () => {
            this.status = 'play';
        });

        audio.addEventListener('pause', () => {
            this.status = 'pause';
        });
    },
    methods: {
        //播放一首歌
        playNew(options) {
            if (!this.isMounted) {
                document.querySelector('body').appendChild(this.$mount().$el);
                this.isMounted = true;
            }

            this.show();

            const {src, name, cover, artist} = options;
            this.track = {src, name, cover, artist};

            clearTimeout(this.timed || 0);

            this.timed = setTimeout(() => {
                this.hide();
            }, 5000);
        },
        hide() {
            const {audioPlay: el} = this.$refs;
            el.style.right = `-${el.offsetWidth - 30}px`;
        },
        show() {
            const {audioPlay: el} = this.$refs;
            el.style.right = 0;
        },
        play() {
            this.$refs.audio.play();
        },
        pause() {
            this.$refs.audio.pause();
        }
    }
};
</script>

<style lang="scss" scoped>
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.audio-play-container {
    position: fixed;
    bottom: 100px;
    right: 0;
    padding-left: 65px;
    height: 60px;
    width: auto;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 100px 0px 0px 100px;
    line-height: 60px;
    box-sizing: border-box;
    cursor: default;
    transition: all 0.5s;

    .audio-play-cover,
    .audio-play-control {
        position: absolute;
        left: 5px;
        top: 50%;
        margin-top: -25px;
        height: 50px;
        width: 50px;
        border-radius: 50px;

        &.play {
            animation: spin 2s linear infinite;
        }
    }

    .audio-play-control {
        background-color: #00000080;
        z-index: 10;
        text-align: center;
        line-height: 50px;
        display: none;

        svg {
            color: #fff;
            font-size: 14px;
        }
    }

    .audio-play-content {
        display: inline-block;
        white-space: nowrap;
        vertical-align: middle;
        margin-right: 10px;
        line-height: 1;

        h3 {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            max-width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            margin-top: 8px;
            font-size: 12px;
            color: #909399;
            max-width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:hover .audio-play-control {
        display: block;
        cursor: pointer;
    }
}
</style>
