// import {asyncRoutes, constantRoutes} from '@/router';

const state = {
    //privileges
    privileges: [],
    // permissionRoutes
    permissionRoutes: [],
    // constantRoutes.concat(asyncRoutes)
    // user permission
    permission: new Set(),
    // user info
    info: null,
    // user msg number
    msg: 0
};

const mutations = {
    SET_PRIVILEGES: (state, data) => {
        state.privileges = data || [];
    },
    SET_PERMISSIONROUTES: (state, data) => {
        state.permissionRoutes = data;
    },
    SET_USER_PERMISSION: (state, data) => {
        state.permission = data;
    },
    SET_USER_INFO: (state, data) => {
        state.info = data;
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_MSG: (state, msg) => {
        state.msg = msg;
    }
};

const actions = {
    setUserInfo({commit}, info) {
        commit('SET_USER_INFO', info);
    },
    // 后台登出
    Logout({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '');
            resolve();
        });
    },
    // 前端登出
    logoutFront({commit}) {
        commit('SET_TOKEN', '');
        location.reload();
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
