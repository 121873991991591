import router from './router';
import store from '@/store';
import {fetchUserInfo} from '@/api/user';
import {getToken, removeToken} from '@/utils/auth'; // get token from cookie
import {MessageBox} from 'element-ui';
import i18n from '@/locales';

let currentFullPath = null;

router.beforeEach(async (to, from, next) => {
    currentFullPath = to.fullPath;

    if (getToken()) {
        if (to.name === 'login') {
            return next({name: 'dashboard'});
        }

        // check userInfo
        if (!store.getters.user) {
            // 是否为免登陆页面
            const isLoginWhite = to.meta.loginWhite || false;

            const res = await fetchUserInfo(isLoginWhite).catch(err => {
                return {data: {state: false, msg: err}};
            });

            // 用户信息获取失败
            if (!res.data.state) {
                removeToken();

                if (isLoginWhite) {
                    next();
                } else {
                    next({name: 'login', query: {redirect: to.name}});
                }

                return;
            }

            // 存储用户信息
            const {
                authStatus,
                userInfo: {spId, spName, name, id}
            } = res.data.result;

            store.dispatch('user/setUserInfo', {authStatus, spName, spId, name, id});
        }

        // check auth | 0--未认证、1--待审核、2--认证成功、3--审核失败
        const _authStatus = store.getters.user.authStatus;
        // 处理认证失败
        if (_authStatus !== 2 && to.meta && to.meta.auth) {
            await MessageBox.alert(i18n.t(`page.authConfirm_${_authStatus}`), i18n.t('page.prompt'), {
                confirmButtonText: i18n.t(`page.authBtn_${_authStatus}`),
                showClose: false
            });

            // 未认证、认证失败 跳转到认证页面
            if (_authStatus === 0 || _authStatus === 3) {
                return next({name: 'personalCenter-certificationInformation'});
            }

            // 待审核状态 直接输入需要认证页面地址 跳转到概览页面
            if (!from.name) {
                return next({name: 'dashboard'});
            }

            // 待审核状态 停留在原页面
            return;
        }

        // 帮助文档
        if (to.name === 'documentation') {
            window.open('https://doc.dmhmusic.com/document/');
            return;
        }

        next();
    } else {
        // 免登陆页面
        if (to.meta.loginWhite) {
            return next();
        }

        next({name: 'login', query: {redirect: to.name}});
    }
});

// 处理发版后 提示用户刷新应用
router.onError(async error => {
    if (error.toString().indexOf('ChunkLoadError') === -1) {
        return;
    }

    location.href = currentFullPath;
});
