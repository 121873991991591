const callQuery = {
    interface: '接口',
    callsToday: '今日调用次数',
    callsYesterday: '昨日调用次数'
};

const errorLog = {
    topTips: '错误日志包含回传数据在清洗过程中的错误返回，请依据错误情况修正回传',
    exigencyError: '紧急错误',
    ordinaryError: '普通错误',
    severityError: '严重错误',
    logDetails: '日志明细',
    time: '时间',
    errorField: '错误字段',
    urgency: '紧急度',
    errorDesc: '错误描述',
    souceFileInfo: '源文件信息',
    placeholderAllField: '全部字段'
};

export default {
    callQuery,
    errorLog
};
