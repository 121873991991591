export default {
    placeholderUsername: '用户名',
    placeholderPassword: '密码',
    noLoginTips: '15天内免登录',
    submit: '登录',
    forgetPassword: '忘记密码',
    register: '立即注册',
    usernameRequired: '请输入用户名',
    passwordRequired: '请输入密码',
    title: 'DMH账号登录'
};
