<template>
    <div :class="classObj" class="app-wrapper">
        <div class="app-header">
            <navbar />
        </div>
        <div class="app-aside">
            <sidebar class="sidebar-container" />
        </div>
        <div class="app-container">
            <main>
                <breadcrumb></breadcrumb>
                <div class="app-body">
                    <router-view tag="main" />
                </div>
            </main>
            <footer-bar />
        </div>
    </div>
</template>
<script>
import Breadcrumb from './Breadcrumb';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import FooterBar from './Footer';

export default {
    components: {
        Breadcrumb,
        Sidebar,
        Navbar,
        FooterBar
    },
    computed: {
        sidebar() {
            return this.$store.state.app.sidebar;
        },
        device() {
            return this.$store.state.app.device;
        },
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened
            };
        }
    }
};
</script>
