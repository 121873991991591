const state = {
    // 国际化语言
    language: localStorage.getItem('language') || 'zh-hans',
    // 侧边栏状态
    sidebar: {
        opened: true,
        withoutAnimation: false
    },
    // 应用配置
    config: {}
};

const mutations = {
    SET_LANGUAGE: (state, language) => {
        state.language = language;
        localStorage.setItem('language', language);
    },
    TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened;
        state.sidebar.withoutAnimation = false;
        if (state.sidebar.opened) {
            localStorage.setItem('sidebarStatus', 1);
        } else {
            localStorage.setItem('sidebarStatus', 0);
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        localStorage.setItem('sidebarStatus', 0);
        state.sidebar.opened = false;
        state.sidebar.withoutAnimation = withoutAnimation;
    },
    SET_CONFIG: (state, data) => {
        state.config = data;
    }
};

const actions = {
    setLanguage({commit}, language) {
        commit('SET_LANGUAGE', language);
    },
    toggleSideBar({commit}) {
        commit('TOGGLE_SIDEBAR');
    },
    closeSideBar({commit}, {withoutAnimation}) {
        commit('CLOSE_SIDEBAR', withoutAnimation);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
