import request from '@/utils/request';
/**
 * @description 登录
 * @param {String} username 用户名
 * @param {String} password 密码
 * @param {String} noLogin 是否免登录 | true--是、false--否
 */
export function login({username, password, noLogin}) {
    return request({
        url: '/account/login',
        method: 'post',
        data: {userName: username, password, noLogin}
    });
}

/**
 * @description 登出
 */
export function loginOut() {
    return request({
        url: '/account/logout',
        method: 'post'
    });
}

/**
 * @description 获取用户信息
 * @param {Boolean} isLoginWhite request中是否对登录状态拦截处理 | true--是、false--否
 */
export function fetchUserInfo(isLoginWhite = false) {
    return request({
        url: '/account/user_info',
        method: 'get',
        headers: {
            isLoginWhite
        }
    });
}

/**
 * @description 消息任务
 */
export function messagesTask() {
    return request({
        url: '/index/sysNum',
        method: 'get',
        headers: {
            // 循环查询任务 request中是否对登录状态拦截处理
            isLoginWhite: true
        }
    });
}

/**
 * @description 注册
 * @param {String} token 邀请令牌 | 邀请注册时必填
 * @param {String} name 用户名
 * @param {String} password 密码
 * @param {String} smsCode 短信验证码
 * @param {String} telArea 国家/地区 区号
 * @param {String} telCountryCode 国家/地区 如CHN
 * @param {String} telephone 手机号
 */
export function register({token, name, password, smsCode, telArea, telCountryCode, telephone}) {
    return request({
        url: '/account/reg',
        method: 'post',
        data: {token, name, password, smsCode, telArea, telCountryCode, telephone}
    });
}

/**
 * @description 找回密码
 * @param {String} smsCode 短信验证码
 * @param {String} telCountryCode 国家/地区 如CHN
 * @param {String} telephone 手机号
 */
export function retrievePassword({smsCode, telCountryCode, telephone}) {
    return request({
        url: '/account/forgetPwd',
        method: 'post',
        data: {smsCode, telCountryCode, telephone}
    });
}

/**
 * @description 重置密码
 * @param {String} password 密码
 * @param {String} token 短信验证令牌
 */
export function resetPassword({password, token}) {
    return request({
        url: '/account/resetPws',
        method: 'post',
        data: {password, token}
    });
}

/**
 * @description 获取邀请信息
 * @param {String} token 邮件返回令牌
 */
export function fetchInvite(token) {
    return request({
        url: '/account/spInviteMsg',
        method: 'get',
        params: {token}
    });
}

/**
 * @description 获取控制面板信息
 */
export function fetchDashboard() {
    return request({
        url: '/index/dashboard',
        method: 'get'
    });
}

/**
 * @description 获取用户基本信息
 */
export function fetchUserBaseInfo() {
    return request({
        url: '/member/userInfo',
        method: 'get'
    });
}

/**
 * @description 更新用户信息
 * @param {String} contact 联系人
 * @param {String} mail 邮箱
 * @param {String} qq QQ
 */
export function updateUserInfo({contact, mail, qq}) {
    return request({
        url: '/member/updateUserInfo',
        method: 'post',
        data: {contact, mail, qq}
    });
}

/**
 * @description 更新密码
 * @param {String} oldPassword 旧密码
 * @param {String} password 新密码
 */
export function updatePassword({oldPassword, password}) {
    return request({
        url: '/member/updatePwd',
        method: 'post',
        data: {oldPassword, password}
    });
}

/**
 * @description 发送短信验证码
 * @param {String} randstr 腾讯验证码客户端验证回调的随机串
 * @param {String} ticket 腾讯验证码客户端验证回调的票据
 * @param {String} telephone 手机号
 * @param {Number} telCountryCode 国家/地区区号
 * @param {Number} type 短信类型 | 0--注册、1--找回密码、2--修改手机号码
 */
export function sendSMSCode({randstr, ticket, telephone, telCountryCode, type = 0}) {
    let url = '/account/sendRegCode';

    if (type === 1) {
        url = '/account/sendForgetCode';
    }

    if (type === 2) {
        url = '/member/sendUpPhoneCode';
    }

    return request({
        url,
        method: 'post',
        data: {randstr, ticket, telephone, telCountryCode}
    });
}

/**
 * @description 更新手机号码
 * @param {String} smsCode 短信验证码
 * @param {String} telArea 国家/地区 区号
 * @param {String} telCountryCode 国家/地区 如CHN
 * @param {String} telephone 手机号
 */
export function updateCellphone({smsCode, telArea, telCountryCode, telephone}) {
    return request({
        url: '/member/updatePhone',
        method: 'post',
        data: {smsCode, telArea, telCountryCode, telephone}
    });
}

/**
 * @description 获取我的消息
 * @param {Number} pageNo
 * @param {Number} pageSize
 */
export function fetchMessage({pageNo, pageSize}) {
    return request({
        url: '/spSysMsg/list',
        method: 'get',
        params: {pageNo, pageSize}
    });
}
